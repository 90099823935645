import { createSlice } from "@reduxjs/toolkit";

const CouponDetailsSlice = createSlice({
  name: "CouponDetails",
  initialState: {
    Coupons: []
  },
  reducers: {
    setCoupons: (state, action) => {
      state.Coupons = action.payload;
    },
  },
});

export const { 
  setCoupons,
} = CouponDetailsSlice.actions;
export default CouponDetailsSlice.reducer;
