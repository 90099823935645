import { createSlice } from "@reduxjs/toolkit";

const ProductDetailsSlice = createSlice({
  name: "ProductDetails",
  initialState: {
    Products: []
  },
  reducers: {
    setProducts: (state, action) => {
      state.Products = action.payload;
    },
  },
});

export const { 
  setProducts,
} = ProductDetailsSlice.actions;
export default ProductDetailsSlice.reducer;
