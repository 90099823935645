import { configureStore } from "@reduxjs/toolkit";
import ProductDetailsSliceReducer from "./slices/ProductDetailsSlice";
import CouponDetailsSliceReducer from "./slices/CouponDetailsSlice";

export const store = configureStore({
  reducer: {
    ProductDetails: ProductDetailsSliceReducer,
    CouponDetails: CouponDetailsSliceReducer
  },
});
